<template>
  <RouterView />
</template>

<script setup>
import { RouterView } from 'vue-router'
import { useHead } from '@unhead/vue'
import { captureUnhandledRejection } from '@/utils/capture-unhandled-rejection'

const imageBucketUrl = import.meta.env.VITE_S3_BUCKET_URL

document.title = import.meta.env.VITE_MERCHANT_NAME

useHead({
  link: [
    { href: `${imageBucketUrl}/images/icons/favicon.ico`, rel: 'shortcut icon' },
    { href: `${imageBucketUrl}/images/icons/favicon-16x16.png`, rel: 'icon', sizes: '16x16' },
    { href: `${imageBucketUrl}/images/icons/favicon-32x32.png`, rel: 'icon', sizes: '32x32' },
    {
      href: `${imageBucketUrl}/images/icons/apple-touch-icon-57x57.png`,
      rel: 'apple-touch-icon',
      sizes: '57x57'
    },
    {
      href: `${imageBucketUrl}/images/icons/apple-touch-icon-60x60.png`,
      rel: 'apple-touch-icon',
      sizes: '60x60'
    },
    {
      href: `${imageBucketUrl}/images/icons/apple-touch-icon-72x72.png`,
      rel: 'apple-touch-icon',
      sizes: '72x72'
    },
    {
      href: `${imageBucketUrl}/images/icons/apple-touch-icon-76x76.png`,
      rel: 'apple-touch-icon',
      sizes: '76x76'
    },
    {
      href: `${imageBucketUrl}/images/icons/apple-touch-icon-114x114.png`,
      rel: 'apple-touch-icon',
      sizes: '114x114'
    },
    {
      href: `${imageBucketUrl}/images/icons/apple-touch-icon-120x120.png`,
      rel: 'apple-touch-icon',
      sizes: '120x120'
    },
    {
      href: `${imageBucketUrl}/images/icons/apple-touch-icon-144x144.png`,
      rel: 'apple-touch-icon',
      sizes: '144x144'
    },
    {
      href: `${imageBucketUrl}/images/icons/apple-touch-icon-152x152.png`,
      rel: 'apple-touch-icon',
      sizes: '152x152'
    },
    {
      href: `${imageBucketUrl}/images/icons/apple-touch-icon-180x180.png`,
      rel: 'apple-touch-icon',
      sizes: '180x180'
    },
    {
      href: `${imageBucketUrl}/images/icons/android-icon-48x48.png`,
      rel: 'icon',
      sizes: '48x48',
      type: 'image/png'
    },
    {
      href: `${imageBucketUrl}/images/icons/android-icon-96x96.png`,
      rel: 'icon',
      sizes: '96x96',
      type: 'image/png'
    },
    {
      href: `${imageBucketUrl}/images/icons/android-icon-144x144.png`,
      rel: 'icon',
      sizes: '144x144',
      type: 'image/png'
    },
    {
      href: `${imageBucketUrl}/images/icons/android-icon-192x192.png`,
      rel: 'icon',
      sizes: '192x192',
      type: 'image/png'
    },
    { href: `${imageBucketUrl}/images/icons/manifest.json`, rel: 'manifest' }
  ]
})
captureUnhandledRejection()
</script>

<style scoped></style>
