import Honeybadger from '@honeybadger-io/js'

const captureUnhandledRejection = () => {
  window.onunhandledrejection = (event) => {
    const errorMessage = event.reason?.message || event.reason || 'Unknown error'
    const errorStack = event.reason?.stack || 'No stack trace available'
    const currentUrl = window.location.href

    Honeybadger.notify('Unhandled promise rejection', {
      metadata: { errorMessage, errorStack, currentUrl, timestamp: new Date().toISOString() }
    })

    event.preventDefault()
  }
}

export { captureUnhandledRejection }
