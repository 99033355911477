import { FILTER_OPERANDS } from '@/services/api/base'

const imageBucketUrl = import.meta.env.VITE_S3_BUCKET_URL

export enum UserRole {
  SYSTEM = 'system',
  ADMIN = 'admin',
  ANALYST = 'analyst',
  SALES = 'sales',
  SUPERVISOR = 'supervisor'
}

export enum ORDER_STATUSES {
  PENDING = 'Pending',
  INCOMPLETE = 'Incomplete',
  UNCAPTURED = 'Uncaptured',
  CAPTURED = 'Captured',
  COMPLETED = 'Completed',
  REFUNDED = 'Refunded',
  CANCELLED = 'Cancelled'
}

export const ACTION_ROLES = {
  CARD_PASS_CREATE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  CARD_PASS_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  CARD_PASSES_IMPORT: [UserRole.SYSTEM, UserRole.ADMIN],
  CARD_PASSES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  CUSTOMER_DETAILS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  CUSTOMER_PASSWORD_RESET: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  CUSTOMER_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  CUSTOMERS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  MOBILE_PASS_DETAILS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  MOBILE_PASS_REGISTRATIONS_VIEW: [
    UserRole.SYSTEM,
    UserRole.ADMIN,
    UserRole.SUPERVISOR,
    UserRole.SALES,
    UserRole.ANALYST
  ],
  MOBILE_PASS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  MOBILE_PASS_VOID: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  MOBILE_PASS_RESEND: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  NOTE_CREATE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  NOTE_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN],
  NOTES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  ORDER_DETAILS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  ORDER_EMAIL_HISTORY: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  ORDERS_EXPORT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  ORDER_PASSES_PRINT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  ORDER_REFUND: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  ORDER_RESEND: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  ORDERS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  PAYLOADS_DECRYPT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  PRODUCT_PUBLISH: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  PRODUCT_DELETE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  PRODUCTS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  QR_CODES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  QR_CODE_EDIT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  QR_CODE_ENABLE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  QR_CODE_DISABLE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  REPORTS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  SCANS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  SETTINGS_CREATE: [UserRole.SYSTEM, UserRole.ADMIN],
  SETTINGS_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN],
  SETTINGS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN],
  USER_CREATE: [UserRole.SYSTEM, UserRole.ADMIN],
  USER_PASSWORD_RESET: [UserRole.SYSTEM, UserRole.ADMIN],
  USER_ROLE_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN],
  USER_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN],
  USERS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  USER_DETAILS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  USER_SECURITY_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SECURITY_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  PAYMENT_TERMINALS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  VESSELS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SIGN_OUT_SESSION: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SYSTEM_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_BUSINESS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_LOCATIONS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_SALESPOINTS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_PRINTERS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_PRINTER_TYPES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_FARES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_ROUTES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_ZONES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_ZONE_TYPES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_PAYMENT_TERMINALS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_VESSELS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_SYSTEM_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR]
}

export const MENU_ITEMS = [
  {
    title: 'Orders',
    to: 'orders',
    icon: 'cart-shopping',
    roles: ACTION_ROLES.ORDERS_VIEW
  },
  {
    title: 'Customers',
    to: 'customers',
    icon: 'users',
    roles: ACTION_ROLES.CUSTOMERS_VIEW
  },
  {
    title: 'Scans',
    to: 'scans',
    icon: 'barcode-read',
    roles: ACTION_ROLES.SCANS_VIEW
  },
  // {
  //   title: 'Products',
  //   to: 'products'
  // },
  // {
  //   title: 'Cards',
  //   to: 'cards',
  //   icon: 'cards-blank'
  // },
  {
    title: 'Passes',
    to: 'passes',
    icon: 'mobile-notch',
    roles: ACTION_ROLES.MOBILE_PASS_VIEW
  },
  {
    title: 'Users',
    to: 'users',
    icon: 'id-card',
    roles: ACTION_ROLES.USERS_VIEW
  },
  {
    title: 'QR Codes',
    to: 'qr_codes',
    icon: 'qrcode',
    roles: ACTION_ROLES.QR_CODES_VIEW
  },
  {
    title: 'Reports',
    to: 'reports',
    icon: 'chart-line',
    roles: ACTION_ROLES.REPORTS_VIEW
  },
  {
    title: 'Settings',
    to: 'settings',
    icon: 'gear',
    roles: ACTION_ROLES.SETTINGS_VIEW
  }
]

export const SECONDARY_MENU_ITEMS = [
  {
    title: 'Stripe',
    to: 'https://dashboard.stripe.com/',
    icon: 'stripe-s',
    external: true,
    iconType: 'brands',
    roles: [UserRole.ADMIN]
  },
  {
    title: 'Pronto',
    to: 'https://app.prontocx.com/',
    external: true,
    img: `${imageBucketUrl}/images/icons/pronto-icon.png`,
    roles: [UserRole.ADMIN]
  }
]

export const AVAILABLE_CC_ICONS = ['visa', 'mastercard', 'amex', 'discover', 'diners', 'unionpay', 'jcb']

export const MAPPED_CC_NETWORKS: { [network: string]: string } = {
  amex: 'American Express',
  diners: 'Diners Club',
  unionpay: 'UnionPay',
  jcb: 'JCB'
}

const routeOptions = () => {
  const routeFilters = import.meta.env.VITE_ROUTE_FILTER_OPTIONS
  return (routeFilters && routeFilters.split(',')) || []
}

const fareOptions = () => {
  const fareFilters = import.meta.env.VITE_FARE_FILTER_OPTIONS
  return (fareFilters && fareFilters.split(',')) || []
}

export const ORDERS_LIST_FILTERS = [
  {
    name: 'date',
    label: 'Date',
    type: 'dateSelector',
    field: 'completed_at',
    fieldOptions: [
      { label: 'Completed', value: 'completed_at' },
      { label: 'Cancelled', value: 'cancelled_at' },
      { label: 'Created', value: 'inserted_at' },
      { label: 'Refunded', value: 'refunded_at' }
    ]
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkboxList',
    field: 'status',
    options: [
      { label: 'Incomplete', value: 'Incomplete' },
      { label: 'Uncaptured', value: 'Uncaptured' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Refunded', value: 'Refunded' },
      { label: 'Partially Refunded', value: 'Partially Refunded' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Cancelled', value: 'Cancelled' }
    ]
  },
  {
    name: 'mediaType',
    label: 'Media type',
    type: 'dropdown',
    field: 'media_type',
    options: [
      { label: 'Card', value: 'card' },
      { label: 'Mobile Pass', value: 'mobile_pass' },
      { label: 'Print', value: 'print' }
    ]
  },
  {
    name: 'route',
    label: 'Route',
    type: 'checkboxList',
    field: 'route',
    options: routeOptions().map((option: string) => ({ label: option, value: option }))
  },
  {
    name: 'fare',
    label: 'Fare',
    type: 'checkboxList',
    field: 'fare',
    options: fareOptions().map((option: string) => ({ label: option, value: option }))
  },
  { name: 'chargedAmount', label: 'Charged amount', type: 'numberRange', field: 'amount_charged' },
  { name: 'discountAmount', label: 'Discount amount', type: 'numberRange', field: 'amount_discount' },
  { name: 'creditAmount', label: 'Credit amount', type: 'numberRange', field: 'amount_credit' },
  {
    name: 'creditTenderType',
    label: 'Credit tender type',
    type: 'checkboxList',
    field: 'credit_tender_type',
    options: [
      { label: 'Cash', value: 'cash' },
      { label: 'Check', value: 'check' },
      { label: 'Gift Certificate', value: 'gift_certificate' },
      { label: 'Other', value: ['cash', 'check', 'gift_certificate'], operand: FILTER_OPERANDS.NOT_IN, separate: true }
    ]
  },
  { name: 'refundAmount', label: 'Refund amount', type: 'numberRange', field: 'amount_refund' },
  {
    name: 'transactionType',
    label: 'Transaction type',
    type: 'checkboxList',
    field: 'transaction_type',
    options: [
      { label: 'Web', value: 'web' },
      { label: 'Terminal', value: 'terminal' }
    ]
  },
  {
    name: 'tenderType',
    label: 'Tender type',
    type: 'checkboxList',
    field: 'pan_funding',
    options: [
      { label: 'Credit Card', value: 'credit' },
      { label: 'Debit Card', value: 'debit' },
      { label: 'Cash', value: 'cash' }
    ]
  },
  {
    name: 'wallet',
    label: 'Wallet',
    type: 'checkboxList',
    field: 'wallet',
    options: [
      { label: 'Apple Wallet', value: 'Apple Wallet' },
      { label: 'Google Wallet', value: 'Google Wallet' },
      { label: 'Other Payment', value: 'Other Payment' }
    ]
  },
  {
    name: 'cardOnFile',
    label: 'Card on File used',
    type: 'dropdown',
    field: 'cof_used',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  },
  {
    name: 'individualTickets',
    label: 'Individual tickets',
    type: 'dropdown',
    field: 'create_individual_passes',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  },
  {
    name: 'newCustomer',
    label: 'New Customer',
    type: 'dropdown',
    field: 'new_customer',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  },
  {
    name: 'guestCustomer',
    label: 'Guest Customer',
    type: 'dropdown',
    field: 'guest',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  }
]

export const QR_CODES_LIST_FILTERS = [
  {
    name: 'status',
    label: 'Status',
    type: 'dropdown',
    field: 'active',
    options: [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '0' }
    ]
  },
  { name: 'views', label: 'Views', type: 'numberRange', field: 'impressions' },
  { name: 'createdAt', label: 'Created date', type: 'dateSelector', field: 'inserted_at' },
  { name: 'updatedAt', label: 'Updated date', type: 'dateSelector', field: 'updated_at' }
]

export const SIGN_OUT_NOTIFICATION = 'sign_out_notification=true'

export const VOIDED_REASONS = {
  mistake: 'Created by mistake',
  duplicate: 'Duplicate',
  fraud: 'Fraud',
  customer_request: 'Requested by customer'
}
