import { useSessionStore } from '@/stores/session'
import { UserRole } from '@/utils/constants'

const hasAccess = (role: UserRole | UserRole[]) => {
  if (!role || role.length === 0) {
    return false
  }

  const sessionStore = useSessionStore()
  const user = sessionStore.getUser()
  const rolesToCheck = Array.isArray(role) ? role : [role]
  return user && rolesToCheck.some((r) => user.roles.includes(r))
}

export const hasAccessMixin = {
  methods: {
    hasAccess
  }
}

export const hasAccessDirective = {
  mounted(el: any, binding: any) {
    if (!hasAccess(binding.value)) {
      el.style.display = 'none'
    }
  },

  updated(el: any, binding: any) {
    if (!hasAccess(binding.value)) {
      el.style.display = 'none'
    } else {
      el.style.display = ''
    }
  }
}
