import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useSessionStore } from '@/stores/session'
import { useNotificationStore } from '@/stores/notification'
import { STATUS_CODES } from '@/services/api/base'
import { setCookie } from '@/services/cookies'
import { ACTION_ROLES } from '@/utils/constants'

export const enum ROUTES {
  AUTO_SIGN_IN = 'autoSignIn',
  CARDS = 'cards',
  CONFIGURATION = 'configuration',
  CUSTOMERS = 'customers',
  CUSTOMER_DETAILS = 'customer_details',
  NOT_FOUND = 'not_found',
  ORDERS = 'orders',
  ORDER_DETAILS = 'order_details',
  PASSES = 'passes',
  PASS_DETAILS = 'pass_details',
  PRODUCTS = 'products',
  QR_CODES = 'qr_codes',
  RESET_PASSWORD = 'resetPassword',
  SCANS = 'scans',
  SECURITY = 'security',
  SETTINGS = 'settings',
  REPORTS = 'reports',
  SEND_MAGIC_LINK = 'sendMagicLink',
  SEND_RESET_PASSWORD_INSTRUCTIONS = 'sendResetPasswordInstructions',
  SIGN_IN = 'signIn',
  SIGN_OUT = 'signOut',
  USERS = 'users',
  USER_DETAILS = 'user_details',
  SETTINGS_BUSINESS = 'settings-business',
  SETTINGS_LOCATIONS = 'settings-locations',
  SETTINGS_SALESPOINTS = 'settings-salespoints',
  SETTINGS_PRINTERS = 'settings-printers',
  SETTINGS_PAYMENT_TERMINALS = 'settings-payment-terminals',
  SETTINGS_VESSELS = 'settings-vessels',
  SETTINGS_SYSTEM = 'settings-system',
  USER_SECURITY = 'user_security'
}

const requireAuthenticatedUser = async (to: RouteLocationNormalized) => {
  const authStore = useAuthStore()
  const sessionStore = useSessionStore()
  let allowAccess = true

  if (authStore.getToken() && authStore.getToken().length > 0) {
    const result = await sessionStore.ping()

    if (result.status_code == STATUS_CODES.UNAUTHORIZED) {
      authStore.clear()
      if ((to.name == ROUTES.ORDERS && !to.redirectedFrom) || to.name != ROUTES.ORDERS) {
        setCookie('dashboard-signin-expired', 'true')
      }
      allowAccess = false
    }
  }

  if (allowAccess && to.meta.roles) {
    const user = sessionStore.getUser()
    const rolesToCheck = Array.isArray(to.meta.roles) ? to.meta.roles : [to.meta.roles]
    allowAccess = rolesToCheck.length == 0 || (!!user && rolesToCheck.some((r) => user.roles.includes(r)))
  }

  if (!allowAccess) {
    return { name: ROUTES.SIGN_IN }
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/sign_in',
      name: ROUTES.SIGN_IN,
      component: () => import('@/views/SignInView.vue')
    },
    {
      path: '/sessions/magic_link/verify',
      name: ROUTES.SEND_MAGIC_LINK,
      component: () => import('@/views/MagicLinkView.vue')
    },
    {
      path: '/user_sessions/auto_sign_in',
      name: ROUTES.AUTO_SIGN_IN,
      beforeEnter: requireAuthenticatedUser,
      component: () => import('@/views/AutoSignInView.vue')
    },
    {
      path: '/password/reset',
      name: ROUTES.SEND_RESET_PASSWORD_INSTRUCTIONS,
      component: () => import('@/views/SendResetPasswordInstructionsView.vue')
    },
    {
      path: '/password/new',
      name: ROUTES.RESET_PASSWORD,
      component: () => import('@/views/ResetPasswordView.vue')
    },
    {
      path: '/',
      redirect: '/orders',
      component: () => import('@/views/BaseView.vue'),
      children: [
        {
          path: '/orders',
          name: ROUTES.ORDERS,
          component: () => import('@/views/OrdersView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.ORDERS_VIEW
          }
        },
        {
          path: '/orders/:id',
          name: ROUTES.ORDER_DETAILS,
          component: () => import('@/views/OrderDetailsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.ORDERS_VIEW
          }
        },
        {
          path: '/customers',
          name: ROUTES.CUSTOMERS,
          component: () => import('@/views/CustomersView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.CUSTOMERS_VIEW
          }
        },
        {
          path: '/customers/:id',
          name: ROUTES.CUSTOMER_DETAILS,
          component: () => import('@/views/CustomerDetailsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.CUSTOMER_DETAILS_VIEW
          }
        },
        {
          path: '/products',
          name: ROUTES.PRODUCTS,
          component: () => import('@/views/ProductsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.PRODUCTS_VIEW
          }
        },
        {
          path: '/cards',
          name: ROUTES.CARDS,
          component: () => import('@/views/CardsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.CARD_PASSES_VIEW
          }
        },
        {
          path: '/passes',
          name: ROUTES.PASSES,
          component: () => import('@/views/PassesView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.MOBILE_PASS_VIEW
          }
        },
        {
          path: '/passes/:id',
          name: ROUTES.PASS_DETAILS,
          component: () => import('@/views/PassDetailsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.MOBILE_PASS_DETAILS_VIEW
          }
        },
        {
          path: '/scans',
          name: ROUTES.SCANS,
          component: () => import('@/views/ScansView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.SCANS_VIEW
          }
        },
        {
          path: '/users',
          name: ROUTES.USERS,
          component: () => import('@/views/UsersView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.USERS_VIEW
          }
        },
        {
          path: '/users/:id',
          name: ROUTES.USER_DETAILS,
          component: () => import('@/views/UserDetailsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.USER_DETAILS_VIEW
          }
        },
        {
          path: '/users/:userId/security',
          name: ROUTES.USER_SECURITY,
          component: () => import('@/views/SecurityView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.USER_SECURITY_VIEW
          }
        },
        {
          path: '/security',
          name: ROUTES.SECURITY,
          component: () => import('@/views/SecurityView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.SECURITY_VIEW
          }
        },
        {
          path: '/reports',
          name: ROUTES.REPORTS,
          component: () => import('@/views/ReportsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.REPORTS_VIEW
          }
        },
        {
          path: '/settings',
          name: ROUTES.SETTINGS,
          component: () => import('@/views/SettingsView.vue'),
          redirect: {
            name: ROUTES.SETTINGS_BUSINESS
          },
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.SETTINGS_VIEW
          },
          children: [
            {
              path: 'business',
              name: ROUTES.SETTINGS_BUSINESS,
              component: () => import('@/views/settings/BusinessSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_BUSINESS_VIEW
              }
            },
            {
              path: 'locations',
              name: ROUTES.SETTINGS_LOCATIONS,
              component: () => import('@/views/settings/LocationsSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_LOCATIONS_VIEW
              }
            },
            {
              path: 'salespoints',
              name: ROUTES.SETTINGS_SALESPOINTS,
              component: () => import('@/views/settings/SalespointsSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_SALESPOINTS_VIEW
              }
            },
            {
              path: 'printers',
              name: ROUTES.SETTINGS_PRINTERS,
              component: () => import('@/views/settings/PrintersSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_PRINTERS_VIEW
              }
            },
            {
              path: 'payment-terminals',
              name: ROUTES.SETTINGS_PAYMENT_TERMINALS,
              component: () => import('@/views/settings/PaymentTerminalsSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_PAYMENT_TERMINALS_VIEW
              }
            },
            {
              path: 'vessels',
              name: ROUTES.SETTINGS_VESSELS,
              component: () => import('@/views/settings/VesselsSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_VESSELS_VIEW
              }
            },
            {
              path: 'system',
              name: ROUTES.SETTINGS_SYSTEM,
              component: () => import('@/views/settings/SystemSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_SYSTEM_VIEW
              }
            }
          ]
        },
        {
          path: '/sign_out',
          name: ROUTES.SIGN_OUT,
          component: () => import('@/views/SignOutView.vue'),
          beforeEnter: requireAuthenticatedUser
        },
        {
          path: '/qr_codes',
          name: ROUTES.QR_CODES,
          component: () => import('@/views/QrCodesView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.QR_CODES_VIEW
          }
        }
      ]
    },
    {
      path: '/404',
      name: ROUTES.NOT_FOUND,
      component: () => import('@/views/NotFoundView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404'
    }
  ]
})

router.afterEach(() => {
  const notificationStore = useNotificationStore()
  notificationStore.clearDisplayed()
})

export default router
