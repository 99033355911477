import 'floating-vue/dist/style.css'
import 'leaflet/dist/leaflet.css'
import './assets/styles/main.css'

import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import HoneybadgerVue from '@honeybadger-io/vue'
import FloatingVue from 'floating-vue'
import { hasAccessDirective, hasAccessMixin } from './mixins/hasAccess.mixin'

import App from './App.vue'
import router from './router'
import 'flowbite'

const app = createApp(App)
const head = createHead()
const pinia = createPinia()
const config = {
  apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
  environment: import.meta.env.VITE_HONEYBADGER_ENVIRONMENT,
  revision: import.meta.env.VITE_HONEYBADGER_REVISION
}

app.use(pinia)
app.use(router)
app.use(head)
app.use(HoneybadgerVue, config)
app.use(FloatingVue)
app.directive('has-access', hasAccessDirective)
app.mixin(hasAccessMixin)

app.$honeybadger.beforeNotify((notice) => {
  if (notice.message && notice.message.includes('The operation either timed out or was not allowed')) {
    return false
  }

  return true
})

app.mount('#app')
